export const environment = {
  production: true,
  envName: 'sandbox',
  api_host: 'https://janus.sandbox-techstreet.com',
  tokenization_url: 'https://sandbox.securepayment.thomsonreuters.com',
  techstreet_store_url: 'store.sandbox-techstreet.com',
  storeLoginUrl: "//store.sandbox-techstreet.com/support/users/login",
  neptune_api: 'https://neptune.sanddbox-techstreet.com',
  neptune_token: '',
  launchDarklyClientId: '65a695d9cdaeb0103c32d4e6',
};
